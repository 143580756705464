// list of words using an array of displayed words and a prop describing the active word

import WordInfo from "./word-info.component";

export default function WordInfoList({ wordArray, wordInfo, activeWord }) {
    let wordArraySorted = wordArray
    if (activeWord) {
        wordArraySorted = wordArraySorted.filter(word => word !== activeWord)
        wordArraySorted.unshift(activeWord)
    }
    return (
    <div className="space-y-2">
        {wordArraySorted.map(word => <WordInfo key={word} word={wordInfo[word]} isActive={word === activeWord} />)}
    </div>
        );
  }