import FunctionalWord from "./functional-word.component";

export default function TextReader({ wordArray, onWordClick }) {
  return (
    <div>
      {wordArray.map((word) =>
        word.included ? (
          <FunctionalWord
            key={word.key}
            wordText={word.word_text}
            wordTranslations={word.translations.map(e => e.word).slice(0, 5).join(", ")}
            onClick={() => onWordClick(word["key"])}
          />
        ) : (
          <p
            className={`inline text-lg ${
              (word.pos === "PUNCT" ||
                word.pos === "SPACE" ||
                word.word_text.slice(0, 1) === "'") &&
              "-ml-1"
            } ${word.word_text !== "-" && "mr-1"}`}
          >
            {word.word_text}
          </p>
        )
      )}
    </div>
  );
}
