import React from "react";
import { Paper, Skeleton } from "@mantine/core";
import Word from "./text-reader/functional-word.component";
import WordInfoList from "./sidebar/word-info-list.component";
import Explainer from "./explainer.component";
import TextReader from "./text-reader/text-reader.component";
import TextInput from "./text-input/text-input.component";

class Translate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isTextInput: true,
      isTextLoading: false,
      isSidebar: false,
      isSidebarLoading: false,

      submittedTextInput: "",
      wordTranslationArray: [],

      // info list
      wordDisplayArray: [],
      wordInfoDict: null,
      activeWord: "",
    };
  }

  componentDidMount() {
    document.addEventListener("keydown", this.onKeyPress);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyPress);
  }

  onKeyPress = (event) => {
    if (event.key === "e") {
      this.setState({
        isTextInput: true,
      });
    } else if (event.key === "r" && !this.state.isTextLoading) {
      this.setState({
        isTextInput: true,
        isSidebar: false,
        submittedTextInput: "",
      });
    }
  };

  submitText = (textInput) => {
    this.setState({
      isTextLoading: true,
      isSidebar: true,
      isSidebarLoading: true,
      submittedTextInput: textInput,
    });
    fetch(
      `${process.env.REACT_APP_API_URL}/translate?text=${textInput}&text_language=en&translation_language=de`,
      { method: "GET", redirect: "follow" }
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          wordTranslationArray: data.translation_array,
          isTextInput: false,
          isTextLoading: false,
        });
        this.fetchSideBar(data.rq_job_id);
      })
      .catch((error) => {
        window.alert(error);
        this.setState({ isTextLoading: false });
      });
  };

  fetchSideBar = (rq_job_id) => {
    fetch(`${process.env.REACT_APP_API_URL}/result/${rq_job_id}`, {
      method: "GET",
      redirect: "follow",
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else if (response.status === 202) {
          console.log(response);
          setTimeout(() => this.fetchSideBar(rq_job_id), 1000);
        } else {
          throw Error("Something went wrong");
        }
      })
      .then((data) => {
        if (data) {
          this.setState({
            wordInfoDict: data.info_dict,
            wordDisplayArray: data.display_array.slice(0, 3),
            isSidebarLoading: false,
          });
        }
      })
      .catch((error) => {
        window.alert(error);
        this.setState({ isSidebarLoading: false });
      });
  };

  displayWordInfo = (key) => {
    this.setState({ activeWord: key });
    if (!this.state.wordDisplayArray.includes(key)) {
      this.setState((prevState) => ({
        wordDisplayArray: [...prevState.wordDisplayArray, key],
      }));
    }
  };

  render() {
    return (
      <div className="container mx-auto px-8 pt-8">
        <Explainer />
        <div className="grid grid-rows-2 md:grid-cols-2 pt-6 gap-6">
          <div className="row-start">
            <Paper padding="xl" shadow="xs">
              <div>
                {this.state.isTextInput ? (
                  <TextInput
                    initialInputValue={this.state.submittedTextInput}
                    onSubmitText={(text) => this.submitText(text)}
                    isLoading={this.state.isTextLoading}
                  />
                ) : (
                  <TextReader
                    wordArray={this.state.wordTranslationArray}
                    onWordClick={(key) => this.displayWordInfo(key)}
                  />
                )}
              </div>
            </Paper>
          </div>
          <div
            className={
              !this.state.isSidebar && "bg-orange-50 rounded-md shadow-sm"
            }
          >
            {this.state.isSidebar && (
              <div>
                {this.state.isSidebarLoading ? (
                  <div>
                    <p className="pb-4 text-normal font-medium text-gray-700">
                      Loading word info...
                    </p>
                    <Skeleton height={16} radius="xl" width="90%" />
                    <Skeleton height={16} mt={12} radius="xl" width="90%" />
                    <Skeleton height={16} mt={12} width="70%" radius="xl" />
                  </div>
                ) : (
                  <WordInfoList
                    wordArray={this.state.wordDisplayArray}
                    wordInfo={this.state.wordInfoDict}
                    activeWord={this.state.activeWord}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Translate;
