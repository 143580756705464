import logo from "../assets/logo.png";
import logoSmall from "../assets/logo_small.png";

export default function Header() {
  return (
    <div className="flex justify-start items-center mt-12 px-8">
      <a target="_blank" rel="noopener noreferrer" href="https://vocab.so/">
        <img
          className="block lg:hidden h-8 w-auto"
          src={logoSmall}
          alt="vocab logo small"
        />
      </a>

      <a target="_blank" rel="noopener noreferrer" href="https://vocab.so/" >
        <img
          className="hidden lg:block h-6 w-auto"
          src={logo}
          alt="vocab logo"
        />
      </a>
    </div>
  );
}