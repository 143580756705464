import React, { useState } from "react";
import { Paper, Kbd } from "@mantine/core";
import WordInfoSubsection from "./word-info-subsection.component";
import { useHotkeys } from "@mantine/hooks";

export default function WordInfo({ word, isActive }) {
  const [count, setCount] = useState(0);
  useHotkeys([
    [
      "D",
      () => {
        if (count >= word.definitions.length - 1) {
          setCount(0);
        } else {
          setCount(count + 1);
        }
      },
    ],
  ]);
  if (isActive) {
    return (
      <Paper padding="xs" shadow="sm" radius="xs">
        <div className="space-y-2">
          <div className="flex justify-between">
            <p>
              {word.word_text} {word.pos}
            </p>
          </div>
          <WordInfoSubsection
            label="Translations"
            body={word.translations.map(e => e.word).join(", ")}
          />
          <WordInfoSubsection
            label={
              <p>
                Definitions {(word.definitions.length > 1) && <Kbd>d</Kbd>}
              </p>
            }
            body={`${word.definitions[count]["text"]} (Source: ${word.definitions[count]["source"]})`}
          />
          <WordInfoSubsection
            label="Synonyms"
            body={word.synonyms.slice(0, 9).join(", ")}
          />
          <WordInfoSubsection
            label="Related Words"
            body={word.related_words.slice(0, 9).join(", ")}
          />
        </div>
      </Paper>
    );
  } else {
    return (
      <Paper padding="xs" shadow="sm" radius="xs">
        <div className="flex justify-between">
          <p>
            {word.word_text} | {word.translations.map(e => e.word).slice(0, 5).join(", ")}
          </p>
        </div>
      </Paper>
    );
  }
}
