import { Kbd } from "@mantine/core";

function Explainer() {
  return (
    <p>
      <b>Hover over words</b> you don't know to get translations.{" "}
      <b>Click a word</b> to get more info. 
      When you've opened a word, press <Kbd>d</Kbd> to toggle between
      definitions. If you want to change your text, press <Kbd>e</Kbd> to edit or <Kbd>r</Kbd> to start over.
    </p>
  );
}

export default Explainer;

// You can switch between words appearing on the right using your keyboard <Kbd>1</Kbd>- <Kbd>9</Kbd>.
// Press <Kbd>←</Kbd> to remove a word from the sidebar. 