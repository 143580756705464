import React, { useState } from "react";
import { Tooltip } from "@mantine/core";

export default function FunctionalWord({
  wordTranslations,
  wordText,
  onClick,
}) {
  const [opened, setOpened] = useState(false);

  return (
    <Tooltip label={wordTranslations} color="yellow" opened={opened} withArrow>
      <div
        variant="outline"
        onMouseEnter={() => setOpened(true)}
        onMouseLeave={() => setOpened(false)}
        onClick={() => {
          setOpened(false);
          onClick();
        }}
        className={`cursor-pointer mr-1 ${opened && "bg-yellow-100"}`}
      >
        <p className="text-lg">{wordText}</p>
      </div>
    </Tooltip>
  );
}
