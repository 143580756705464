import React, { useState } from "react";
import { Textarea, Button, Kbd, LoadingOverlay } from "@mantine/core";
import { useHotkeys } from "@mantine/hooks";

export default function TextInput({ onSubmitText, isLoading, initialInputValue }) {
  const [isEditable, setIsEditable] = useState(false);
  const [textInput, setTextInput] = useState(initialInputValue);

  useHotkeys([
    [
      "Enter",
      () => {
        if (isEditable) {
          assertSubmitText(textInput);
        }
      },
    ],
  ]);

  const assertSubmitText = (text) => {
    if (text.length > 5000) {
      window.alert("The text is too long.");
    } else if (text.split(" ").length < 4) {
      window.alert("The text is too short.");
    } else {
      onSubmitText(text);
    }
  };

  return (
    <div className="relative">
      <Textarea
        value={textInput}
        onChange={(event) => {
          const newInputValue = event.target.value;
          setTextInput(newInputValue);
          if (!isEditable) {
            if (newInputValue.split(" ").length < 4) {
              setIsEditable(true);
            } else {
              assertSubmitText(newInputValue);
            }
          }
        }}
        placeholder={isEditable ? "Enter your text..." : "Paste your text..."}
        variant="unstyled"
        size="lg"
        minRows={8}
        disabled={isLoading}
      />
      {isEditable && (
        <div className="absolute bottom-0 right-0">
          <Button
            onClick={() => assertSubmitText(textInput)}
            variant="light"
            color="gray"
          >
            <p className="pr-2">Submit</p>
            <Kbd>↵</Kbd>
          </Button>
        </div>
      )}
      {isLoading && (
        <LoadingOverlay
          loaderProps={{ size: "sm", color: "gray" }}
          visible={true}
          overlayOpacity={0.8}
        />
      )}
    </div>
  );
}
