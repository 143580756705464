import Header from "./components/header.component";
import Translate from "./components/translate.page";

function App() {
  return (
    <div className="bg-amber-50 min-h-screen flex flex-col items-center justify-center">
      <div className="container max-w-5xl">
        <Header />
        <Translate />
      </div>
      <div className="py-6 bg-amber-50 flex flex-col justify-center items-center">
        <div className="flex flex-row justify-center items-center space-x-8 pt-4">
          <p className="text-center text-gray-400 text-sm">&copy; 2022 Vocab</p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://vocab.so/imprint"
            className="text-sm text-gray-400 hover:text-gray-900"
          >
            Imprint
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://vocab.so/privacy"
            className="text-sm text-gray-400 hover:text-gray-900"
          >
            Privacy
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
